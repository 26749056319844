<template>
  <p v-if="errors.error" class="alert alert-danger">{{ errors.message }}</p>
  <div v-if="!auth_data.access_token">
    <div class="row">
      <div class="col-sm-6">
        <p>Bitte loggen Sie sich ein!</p>
      </div>
    </div>
    <form>
      <div class="row">
        <div class="col-sm-6">
          <input
            id="username"
            type="text"
            class="form-control"
            v-model="login_data.user"
            placeholder="Benutzername"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <input
            type="password"
            class="form-control"
            v-model="login_data.pass"
            placeholder="Passwort"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <button
            id="loginButton"
            class="btn btn-block btn-success"
            v-on:click.prevent="login"
          >
            Einloggen
          </button>
        </div>
      </div>
    </form>
  </div>
  <div v-else>
    <h2>Übersicht:</h2>
    <table class="table">
      <thead>
        <tr>
          <th>Anzahl Artikel</th>
          <th>Netto Lagerwert (EK)</th>
          <th>Netto Lagerwert (VK)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ articleCount }}</td>
          <td>{{ netSumEK }} €</td>
          <td>{{ netSumVK }} €</td>
        </tr>
      </tbody>
    </table>
    <h2>Aktive Artikel</h2>
    <input
      type="text"
      class="form-control"
      v-model="searchTerm"
      placeholder="Suche"
    />
    <table class="table">
      <thead>
        <tr>
          <th>Nummer</th>
          <th>Name</th>
          <th>KA</th>
          <th>A</th>
          <th>Bestand</th>
          <th>Min Bestand</th>
          <th>Bestellt</th>
          <th>Backorder</th>
          <th>N/N</th>
          <th>VK Brutto</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <article-view-row
          :product="product"
          @selectedProductChanged="setSelectedProduct"
          v-for="product in activeProducts"
          v-bind:key="product.id"
        />
      </tbody>
    </table>
  </div>
  <article-modal
    :product="selectedProduct"
    v-if="selectedProduct"
    @saveChanges="articleManagerDataChanged"
    :vendors="possibleVendors"
    :competitors="possibleCompetitors"
  />
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :is-full-page="true"
  />
</template>

<script>
import axios from "axios";
import ShopwareAuth from "../ShopwareAuth";
import ArticleModalVue from "./ArticleModal";
import ArticleViewRow from "./ArticleViewRow";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "ArticleManager",
  components: {
    "article-modal": ArticleModalVue,
    "article-view-row": ArticleViewRow,
    Loading,
  },
  data() {
    return {
      isLoading: false,

      auth_data: {
        token_type: null,
        expires_in: null,
        access_token: null,
        refresh_token: null,
      },
      login_data: {
        user: null,
        pass: null,
      },
      errors: {
        error: false,
        message: null,
      },
      rawProductData: null,
      searchTerm: "",
      selectedProduct: {
        name: null,
        description: null,
        productNumber: null,
        customFields: {
          articleManagerData: {
            minStock: 0,
          },
        },
      },
    };
  },
  methods: {
    login() {
      this.isLoading = true;
      const loginButton = document.getElementById("loginButton");
      loginButton.innerHTML = "Bitte warten...";
      loginButton.disabled = true;
      ShopwareAuth.getToken(this.login_data.user, this.login_data.pass).then(
        (response) => {
          loginButton.disabled = false;
          loginButton.innerHTML = "Einloggen";
          if (!response.errors) {
            this.errors.error = false;
            this.auth_data = response;
            localStorage.setItem("auth_data", JSON.stringify(response));
            this.login_data = { user: null, pass: null };
            this.getProducts();
          } else {
            this.errors.error = true;
            this.errors.message = response.errors[0].title;
            document.getElementById("username").focus();
          }
          this.isLoading = false;
        }
      );
    },
    async getProducts() {
      await this.refreshToken();
      if (this.auth_data.access_token) {
        this.isLoading = true;
        axios
          .get("https://apestadel.de/api/product", {
            headers: await ShopwareAuth.getHeaders(this.auth_data),
          })
          .then((response) => {
            this.rawProductData = response.data.data.sort(
              (productA, productB) => {
                return productA.name.localeCompare(productB.name);
              }
            );
            this.isLoading = false;
          });
      }
    },
    setSelectedProduct(product) {
      this.selectedProduct = product;
    },
    async articleManagerDataChanged(evData) {
      this.isLoading = true;
      await this.refreshToken();
      axios
        .patch(
          "https://apestadel.de/api/product/" + evData.id,
          {
            customFields: {
              articleManagerData: evData.articleManagerData,
            },
            stock: evData.stock,
            active: evData.active,
          },
          {
            headers: await ShopwareAuth.getHeaders(this.auth_data),
          }
        )
        .then(() => {
          this.getProducts();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async refreshToken() {
      if (this.auth_data.refresh_token) {
        this.auth_data = await ShopwareAuth.refreshToken(
          this.auth_data.refresh_token
        );
        localStorage.setItem("auth_data", JSON.stringify(this.auth_data));
        if(!this.auth_data.access_token) {
          this.isLoading = false;
        }
      }
    },
  },
  async mounted() {
    let tempAuth_data = JSON.parse(localStorage.getItem("auth_data"));
    if (tempAuth_data != null) {
      this.auth_data = tempAuth_data;
      if (this.auth_data.refresh_token) {
        this.auth_data = await ShopwareAuth.refreshToken(
          this.auth_data.refresh_token
        );
      }
    } else {
      this.auth_data = {
        token_type: null,
        expires_in: null,
        access_token: null,
        refresh_token: null,
      };
    }
    localStorage.setItem("auth_data", JSON.stringify(this.auth_data));
    this.getProducts();
  },
  computed: {
    articleCount() {
      if (this.rawProductData) {
        return this.rawProductData.length;
      } else {
        return 0;
      }
    },
    netSumVK() {
      if (this.rawProductData) {
        return this.rawProductData.reduce((sum, product) => {
          return (
            Number(sum) +
            Number(product.price[0].net) * Number(product.stock)
          ).toFixed(2);
        }, 0);
      } else {
        return 0;
      }
    },
    netSumEK() {
      if (this.rawProductData) {
        const productsWithVendorPrices = this.rawProductData.filter(
          (product) => {
            return (
              product.customFields &&
              product.customFields.articleManagerData &&
              product.customFields.articleManagerData.vendorPrices &&
              product.customFields.articleManagerData.vendorPrices.length > 0
            );
          }
        );
        let allStockValue = 0;
        productsWithVendorPrices.forEach((product) => {
          // Get sum of vendorprices:
          const sum =
            product.customFields.articleManagerData.vendorPrices.reduce(
              (sum, curPrice) => {
                return Number(sum) + Number(curPrice.price);
              },
              0
            );

          const avgPrice =
            Number(sum) /
            product.customFields.articleManagerData.vendorPrices.length;

          const stockValue = avgPrice * Number(product.stock);
          allStockValue += Number(stockValue);
        });
        return allStockValue.toFixed(2);
      } else {
        return 0;
      }
    },
    activeProducts() {
      if (this.rawProductData) {
        return this.rawProductData.filter((product) => {
          return (
            (product.name
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase()) ||
              product.productNumber
                .toLowerCase()
                .includes(this.searchTerm.toLowerCase()) ||
                (
                    product.customFields &&
                    product.customFields.articleManagerData &&
                    product.customFields.articleManagerData.vendorPrices &&
                    product.customFields.articleManagerData.vendorPrices
                        .some(el =>
                            el.number
                            && el.number.toLowerCase()
                                .includes(this.searchTerm.toLowerCase()))
                )
            )
          );
        });
      } else {
        return [];
      }
    },
    possibleVendors() {
      // get all vendors from all products:
      if (this.rawProductData) {
        return this.rawProductData.reduce((vendors, product) => {
          if (
            product.customFields &&
            product.customFields.articleManagerData &&
            product.customFields.articleManagerData.vendorPrices
          ) {
            product.customFields.articleManagerData.vendorPrices.forEach(
              (price) => {
                if (!vendors.includes(price.vendor)) {
                  vendors.push(price.vendor);
                }
              }
            );
          }
          return vendors;
        }, []);
      } else {
        return [];
      }
    },
    possibleCompetitors() {
      // get all competitors from all products:
      if (this.rawProductData) {
        return this.rawProductData.reduce((competitors, product) => {
          if (
            product.customFields &&
            product.customFields.articleManagerData &&
            product.customFields.articleManagerData.compPrices
          ) {
            product.customFields.articleManagerData.compPrices.forEach(
              (price) => {
                if (!competitors.includes(price.competitor)) {
                  competitors.push(price.competitor);
                }
              }
            );
          }
          return competitors;
        }, []);
      } else {
        return [];
      }
    },
  },
};
</script>
